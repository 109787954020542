<template>
    <div id="home-box">
        <h3>首页内容</h3>
        <el-form ref="form" :model="homeInfo" label-width="80px" style="width:500px;">
            <el-form-item label="商品标题">
                <el-input v-model="homeInfo.hot_title"></el-input>
            </el-form-item>
            <el-form-item label="简介标题">
                <el-input type="textarea" v-model="homeInfo.desc_title"></el-input>
            </el-form-item>
            <el-form-item label="简介1">
                <el-input v-model="homeInfo.desc1"></el-input>
            </el-form-item>
            <el-form-item label="简介2">
                <el-input v-model="homeInfo.desc2"></el-input>
            </el-form-item>
            <el-form-item label="简介3">
                <el-input v-model="homeInfo.desc3"></el-input>
            </el-form-item>
            <el-form-item label="简介4">
                <el-input v-model="homeInfo.desc4"></el-input>
            </el-form-item>

            <el-form-item label="轮播图1">
                <div class="pic_item">
                    <el-col :span="18" style="margin-right:15px">
                        <el-input v-model="homeInfo.banner1"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs" :on-success="lunboSucc1" :file-list="lunbo1List" list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                </div>
            </el-form-item>
            <el-form-item label="轮播图2">
                <div class="pic_item">
                    <el-col :span="18" style="margin-right:15px">
                        <el-input v-model="homeInfo.banner2"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs" :on-success="lunboSucc2" :file-list="lunbo2List" list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                </div>
            </el-form-item>
            <el-form-item label="轮播图3">
                <div class="pic_item">
                    <el-col :span="18" style="margin-right:15px">
                        <el-input v-model="homeInfo.banner3"></el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs" :on-success="lunboSucc3" :file-list="lunbo3List" list-type="picture">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                </div>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">确定</el-button>
                <el-button>取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getForm, postJson } from '../tools/tool'
export default {
    data() {
        return {
            homeInfo: {
                hot_title: "",
                desc_title: "",
                desc1: "",
                desc2: "",
                desc3: "",
                desc4: "",
                banner1: "",
                banner2: "",
                banner3: "",
            },
            lunbo1List: [],
            lunbo2List: [],
            lunbo3List: [],
        }
    },
    created() {
        this.onLoad()
    },
    methods: {
        onLoad() {
            getForm("/getHome").then(data => {
                this.homeInfo = data.data
            }).catch(err => {
                alert("err" + err)
            })
        },
        onSubmit() {
            postJson("/updateHome", this.homeInfo).then(data => {
                alert("更新成功")
            }).catch(
                err => { alert("err" + err) }
            )
        },
        lunboSucc1(res) {
            if (res.Code == "200") {
                this.homeInfo.banner1 = res.Data
                this.lunbo1List.push({ name: 'banner1.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        lunboSucc2(res) {
            if (res.Code == "200") {
                this.homeInfo.banner2 = res.Data
                this.lunbo2List.push({ name: 'banner2.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
        lunboSucc3(res) {
            if (res.Code == "200") {
                this.homeInfo.banner3 = res.Data
                this.lunbo3List.push({ name: 'banner3.jpg', url: res.Data })
            }
            alert(res.Msg)
        },
    }
}
</script>

<style>
</style>